import React, { useState } from "react";
import { PopupModal } from "react-calendly";

const CalendlyButton = ({ name, url }) => {
  const [isOpen, setIsOpen] = useState(false)

  const openPopup = () => setIsOpen(true)
  const closePopup = () => setIsOpen(false)

  if (typeof document === `undefined`) {
    return <></>
  }

  return (
    <>
      <button  className="btn-a" onClick={openPopup}>
        <div className="button">
          {name}
          {` `}
          <i className="fa fa-long-arrow-right"></i>
          <div className="mask" />
        </div>
      </button>

      <PopupModal
        url={url}
        onModalClose={closePopup}
        open={isOpen}
        rootElement={document.getElementById("___gatsby")}
      />
    </>
  );
};

export default CalendlyButton;
