import React from "react";
import PropTypes from "prop-types";
import VisibilitySensor from "react-visibility-sensor";
import PageData from "content/banner-content.json";

import CalendlyButton from "../CalendlyButton"

const Banner = (props) => {
  return (
    <div className="home-video-area">
      <video autoPlay="autoplay" loop="loop" id="bgvid">
        <source src={props.VideoLink} type="video/mp4" />
      </video>

      <div className="video-text-area">
        <VisibilitySensor>
          {({ isVisible }) => (
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <span
                    className={
                      isVisible
                        ? "hero-text animated fadeInDown slow opacityOne"
                        : "opacityZero"
                    }
                  >
                    {props.TopTitle}
                  </span>

                  <h1
                    className={
                      isVisible
                        ? "animated fadeInDown slow opacityOne"
                        : "opacityZero"
                    }
                  >
                    {props.Title}
                  </h1>
                  <p
                    className={
                      isVisible
                        ? "animated fadeInDown slow opacityOne"
                        : "opacityZero"
                    }
                  >
                    {props.Content}
                  </p>
                  <div className="center-wrap">
                    <CalendlyButton
                      name={props.BtnName}
                      url={props.CalendlyURL}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </VisibilitySensor>
      </div>
    </div>
  );
};

//Props Types
Banner.propTypes = {
  VideoLink: PropTypes.string,
  TopTitle: PropTypes.string,
  Title: PropTypes.string,
  Content: PropTypes.string,
  CalendlyURL: PropTypes.string,
  BtnName: PropTypes.string,
};

//Default Props
Banner.defaultProps = {
  VideoLink:
    "https://ucarecdn.com/07746138-7669-439a-b4d1-54f810ad798c/video.mp4",
  TopTitle: PageData.topTitle,
  Title: PageData.title,
  Content: PageData.content,
  CalendlyURL: PageData.calendlyURL,
  BtnName: PageData.btnText,
};

export default Banner;
